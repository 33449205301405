<template>
  <div class="bg-white">
    <div class="block block-rounded text-center" id="aboutus">
      <div class="block-content">
        <h2>公司简介</h2>
      </div>
    </div>
    <div class="content">
      <div class="block block-rounded">
        <div class="block-content">
          <p>
            上海库软信息科技有限公司成立于2013年，一群执着沉迷于技术的技术咖组成以研发为主的团队。热爱技术，追求自主研发，执着于中国“智创”。作为国内首家自动生成APP手机组态软件及嵌入式手机组态研发者，奉行“极简主义”，将复杂的编程简单化。历时多年，厚积薄发，技术咖团队自主研发了非专业工程师也能玩转的库软移动SCADA组态软件，并进一步创造了基于软件的嵌入式硬件产品系列：云屏，云盒子，也进一步拓展了云平台系统。
          </p>
           <p>
            目前，产品已经广泛运用到国内外各地各行各业：汽车制造系统，钢铁生产系统，电力系统，空调系统，智能家居系统，矿业系统，电梯系统，环保监控系统，水文监测系统，市政设施监控系统，食品生产系统，PLC远程控制系统，工厂中控室系统等。
          </p>
        </div>
      </div>
    </div>

    <div class="block block-rounded text-center" id="aboutus">
      <div class="block-content">
        <h2>联系我们</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-12">
        <div
          class="block block-rounded block-bordered block-mode-loading-refresh"
        >
          <img
            src="https://oss.cnwscada.com/officalWebsite/assets/image/aboutus.png"
            style="height: 100%; width: 100%"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
a {
  color: #98c593;
}

h2 {
  font-size: 2.25rem;
}

a:hover {
  cursor: pointer;
}

.nav-main-link-name {
  color: #000000;
}
.title {
  font-size: 28px;
  line-height: 120%;
  padding-bottom: 1.5rem;
  text-align: center;
  padding-top: 2.5rem;
}
</style>
